<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Secondary Actions
			</template>
			<template #subtitle>
				Each available Channel on the Primary Widget if consented to can link to a Secondary Widget to obtain more information on each Data Subject.
			</template>
		</SectionCard>
		<SectionCard
			v-for="(channel, index) in progressiveProfile.choices"
			:key="channel.channelId"
			:partially-collapsible="!!channel.widgetId"
			:partially-collapsible-toggle-labels="['Show Preview', 'Show Preview']"
		>
			<template #title>
				Primary Widget Response - '{{ displayChannelName(channel.channelId) }}'
			</template>
			<template #subtitle>
				Display this Widget if the Data Subject is 'opted in' to Cassie Channel '{{ displayChannelName(channel.channelId) }}'.
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:value.sync="channel.widgetId"
							:disabled="!userCanCreateAndUpdate"
							:items="availablePreferenceWidgetItems"
							:label="`Secondary Widget ${index + 1} *`"
							rules="required"
							@input="updateChannelWidget(channel, index, $event)"
						/>
					</v-col>
				</v-row>
			</template>
			<template #collapsible-content>
				<v-row
					v-if="channel.widgetId"
					dense
				>
					<v-col cols="12">
						<PreferenceWidgetPreviewCard
							:preference-widget="selectedPreferenceWidget(channel.widgetId)"
							:extended-preferences="[]"
							:headers="headers"
							:user-full-permissions="userCanCreateAndUpdate"
							:footers="footers"
							:channels="channels"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import PreferenceWidgetPreviewCard from '../preference-widget-preview-card.vue'

export default {
	components: {
		SectionCard,
		Dropdown,
		PreferenceWidgetPreviewCard
	},
	props: {
		progressiveProfile: Object,
		userCanCreateAndUpdate: Boolean,
		isEdit: Boolean,
		preferenceWidgets: Array,
		headers: Array,
		footers: Array,
		channels: Array
	},
	computed: {
		availablePreferenceWidgetItems () {
			return this.preferenceWidgets.filter(widget => widget.value !== this.progressiveProfile.primaryWidgetId)
		}
	},
	methods: {
		selectedPreferenceWidget (id) {
			return this.preferenceWidgets.find(widget => widget.preferenceWidgetId === id)
		},
		updateChannelWidget (channel, index, selectedWidgetId) {
			const changedChannel = channel
			changedChannel.widgetId = selectedWidgetId
			const secondLevelChannels = this.progressiveProfile.choices
			secondLevelChannels[index] = changedChannel
			this.updateProgressiveProfile('choices', secondLevelChannels)
			this.setThirdLevelChannels(index, selectedWidgetId)
		},
		setThirdLevelChannels (index, selectedWidgetId) {
			const choices = JSON.parse(JSON.stringify(this.progressiveProfile.choices))
			const selectedWidget = this.preferenceWidgets.find(widget => widget.preferenceWidgetId === selectedWidgetId)
			const thirdLevelChannels = selectedWidget?.statementGroups?.map(statementGroup => ({
				channelName: statementGroup.channelName,
				channelId: statementGroup.channelId,
				widgetId: null,
				choices: []
			}))
			choices[index].choices = thirdLevelChannels
			this.updateProgressiveProfile('choices', choices)
		},
		updateProgressiveProfile (property, value) {
			this.$emit('update:progressiveProfile', {
				...this.progressiveProfile,
				[property]: value
			})
		},
		displayChannelName (channelId) {
			return this.channels.find(channel => channel.channelId === channelId)?.channelName
		}
	}
}
</script>
