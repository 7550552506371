export const COLOUR_SCHEME_IDENTIFIERS = {
	DARK: 0,
	LIGHT: 1,
	CUSTOM: 2
}

export const COLOUR_SCHEME_RADIOS = [
	{
		value: COLOUR_SCHEME_IDENTIFIERS.DARK,
		label: 'Dark'
	},
	{
		value: COLOUR_SCHEME_IDENTIFIERS.LIGHT,
		label: 'Light'
	},
	{
		value: COLOUR_SCHEME_IDENTIFIERS.CUSTOM,
		label: 'Custom'
	}
]

export const COLOUR_SCHEMES = {
	DARK: {
		Identifier: COLOUR_SCHEME_IDENTIFIERS.DARK,
		BackgroundColour: '#262620',
		TextColour: '#FFFFFF',
		CloseButtonColour: '#FFFFFF'
	},
	LIGHT: {
		Identifier: COLOUR_SCHEME_IDENTIFIERS.LIGHT,
		BackgroundColour: '#E8E8E8',
		TextColour: '#262620',
		CloseButtonColour: '#262620'
	},
	CUSTOM: {
		Identifier: COLOUR_SCHEME_IDENTIFIERS.CUSTOM,
		BackgroundColour: '#FFFFFF',
		TextColour: '#000000',
		CloseButtonColour: '#000000'
	}
}

export const CUSTOM_COLOUR_SCHEME = [
	{
		id: 'BackgroundColour',
		text: 'Background Colour',
		color: '#FFFFFF'
	},
	{
		id: 'TextColour',
		text: 'Text Colour',
		color: '#000000'
	},
	{
		id: 'CloseButtonColour',
		text: 'Close Button Colour',
		color: '#000000'
	}
]
