<template>
	<SectionCard>
		<template #title>
			Preview
		</template>
		<template #body>
			<v-row>
				<v-spacer />
				<v-col
					cols="5"
					class="d-flex justify-end"
				>
					<v-tabs
						v-model="tab"
						class="d-flex justify-end"
					>
						<v-tab
							v-for="deviceType in deviceTypes"
							:key="deviceType.value"
						>
							{{ deviceType.name }}
						</v-tab>
					</v-tabs>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="12"
					class="d-flex justify-center"
				>
					<div
						id="preview_container"
						:style="{
							width: previewContainerWidth,
							maxWidth: previewContainerWidth,
						}"
						class="d-flex flex-column justify-end"
					>
						<div
							id="cassie_widget__container"
							:style="{
								backgroundColor: colourScheme.BackgroundColour,
								color: colourScheme.TextColour,
								'--widget-close-button-color': colourScheme.CloseButtonColour
							}"
						>
							<div id="cassie_widget__header">
								<div
									id="cassie_widget__header__content"
									v-dompurify-html="header"
								/>
								<button id="cassie_widget__close">
									&times;
								</button>
							</div>
							<div
								v-for="statement in statements"
								:key="statement"
								class="cassie-widget--statement"
							>
								<input
									:id="statement"
									type="checkbox"
									aria-label="statement"
								>
								<label :for="statement">
									{{ statement }}
								</label>
							</div>
							<div
								id="cassie_widget__footer"
								v-dompurify-html="footer"
							/>
						</div>
					</div>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'

export default {
	components: {
		SectionCard
	},
	props: {
		header: String,
		footer: String,
		statements: Array,
		colourScheme: Object
	},
	data () {
		const MOBILE = 0
		const TABLET = 1
		const DESKTOP = 2
		return {
			deviceTypes: [
				{ name: 'Mobile', value: 0 },
				{ name: 'Tablet', value: 1 },
				{ name: 'Desktop', value: 2 }
			],
			tab: 2,
			MOBILE,
			TABLET,
			DESKTOP
		}
	},
	computed: {
		previewContainerWidth () {
			switch (this.tab) {
				case this.MOBILE:
					return '325px'
				case this.TABLET:
					return '600px'
				case this.DESKTOP:
					return '100%'
				default:
					return '100%'
			}
		}
	}
}
</script>

<style lang="scss">
#preview_container {
	height: 400px;
	background-color: grey;
}
#cassie_widget__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    bottom: 0;
    background-color: var(--cassie-widget-background);
    color: var(--cassie-widget-text-color);
		height: fit-content;
}

#cassie_widget__container > *:not(:last-child) {
    margin-bottom: 12px;
}

#cassie_widget__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#cassie_widget__close {
    background: none;
    border: none;
    font-size: 24px;
    padding: 0;
    color: var(--widget-close-button-color);
}

#cassie_widget__close:hover {
    cursor: pointer;
}

.cassie-widget--statement > *:not(:last-child) {
    margin-right: 8px;
}
</style>
