<template>
	<validation-provider
		tag="div"
	>
		<div
			v-if="radioGroupLabel"
			class="text-subtitle-2"
		>
			{{ radioGroupLabel }}
			<v-tooltip
				v-if="tooltipText"
				slot="append"
				right
			>
				<template #activator="{ on }">
					<v-icon
						light
						v-on="on"
					>
						mdi-information
					</v-icon>
				</template>
				<span>
					{{ tooltipText }}
				</span>
			</v-tooltip>
		</div>
		<v-radio-group
			:value="radioGroupValue"
			v-bind="$props"
			class="mt-0 cassie-radio-group"
			:class="imageRadioGroup ? 'cassie-image-radio-group' : ''"
			hide-details
			:row="row"
			:disabled="disabled"
			@change="$emit('update:radioGroupValue', $event)"
		>
			<v-radio
				v-for="radio in radios"
				:key="radio.value"
				:label="radio.label"
				:value="radio.value"
				:class="radio.imageSrc ? 'cassie-image-radio' : ''"
			>
				<template
					v-if="radio.imageSrc"
					slot="label"
				>
					<v-img
						:src="radio.imageSrc"
						class="mb-3 cassie-image-radio-image"
					/>
				</template>
			</v-radio>
		</v-radio-group>
	</validation-provider>
</template>
<script>
export default {
	props: {
		radioGroupValue: {
			type: [Number, Boolean]
		},
		radios: {
			type: Array,
			required: true
		},
		row: {
			type: Boolean,
			default: true
		},
		imageRadioGroup: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		radioGroupLabel: {
			type: String
		},
		tooltipText: {
			type: String
		}
	}
}
</script>

<style lang="scss">
.cassie-image-radio {
	display: flex;
	flex-direction: column-reverse;
	padding: 5px;

}
.cassie-image-radio-group .v-input--radio-group__input {
	display: flex;
	> :not(:last-child) {
		margin-right: 32px;
	}
}
.cassie-image-radio-image {
	width: 75px;
}
</style>
