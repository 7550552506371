<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Final Actions
			</template>
			<template #subtitle>
				Based on the Profile configuration, there are {{ finalLevelActionsLength }} distinct final actions to be selected.  Select a Widget for each possible outcome.
			</template>
		</SectionCard>
		<template v-for="(parentChoice, parentIndex) in progressiveProfile.choices">
			<SectionCard
				v-for="(channel, index) in parentChoice.choices"
				:key="`${parentChoice.channelId}-${channel.channelId}`"
				:partially-collapsible="!!channel.widgetId"
				:partially-collapsible-toggle-labels="['Show Preview', 'Show Preview']"
			>
				<template #title>
					Widget Responses - {{ `'${displayChannelName(parentChoice.channelId)}' & '${displayChannelName(channel.channelId)}'` }}
				</template>
				<template #subtitle>
					Display this Widget if the Data Subject is 'opted in' to Cassie Channels {{ `'${displayChannelName(parentChoice.channelId)}' & '${displayChannelName(channel.channelId)}'` }}.
				</template>
				<template #body>
					<v-row dense>
						<v-col cols="6">
							<Dropdown
								:id="`${parentChoice.channelId}-${channel.channelId}`"
								:value.sync="channel.widgetId"
								:disabled="!userCanCreateAndUpdate"
								:items="availablePreferenceWidgetItems(parentChoice.widgetId)"
								:label="finalWidgetLabel(parentChoice.channelId, channel.channelId)"
								rules="required"
								@input="updateChannelWidget(parentIndex, index, $event)"
							/>
						</v-col>
					</v-row>
				</template>
				<template #collapsible-content>
					<v-row
						v-if="channel.widgetId"
						dense
					>
						<v-col cols="12">
							<PreferenceWidgetPreviewCard
								:preference-widget="selectedPreferenceWidget(channel.widgetId)"
								:extended-preferences="[]"
								:headers="headers"
								:user-full-permissions="userCanCreateAndUpdate"
								:footers="footers"
								:channels="channels"
							/>
						</v-col>
					</v-row>
				</template>
			</SectionCard>
		</template>
	</div>
</template>
<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import PreferenceWidgetPreviewCard from '../preference-widget-preview-card.vue'

export default {
	components: {
		SectionCard,
		Dropdown,
		PreferenceWidgetPreviewCard
	},
	props: {
		progressiveProfile: Object,
		userCanCreateAndUpdate: Boolean,
		isEdit: Boolean,
		preferenceWidgets: Array,
		headers: Array,
		footers: Array,
		channels: Array,
		primaryPreferenceWidget: Object
	},
	computed: {
		finalLevelActionsLength () {
			// supports 3 levels deep, this would need altering if we allow a 4th level
			const finalLevelActions = []
			this.progressiveProfile.choices.forEach(choice => {
				choice.choices.forEach(subChoice => {
					finalLevelActions.push(subChoice)
				})
			})
			return finalLevelActions.length
		}
	},
	methods: {
		availablePreferenceWidgetItems (parentwidgetId) {
			return this.preferenceWidgets.filter(
				widget => widget.widgetId !== parentwidgetId && widget.preferenceWidgetId !== this.progressiveProfile.primaryWidgetId
			)
		},
		selectedPreferenceWidget (id) {
			return this.preferenceWidgets.find(widget => widget.preferenceWidgetId === id)
		},
		updateChannelWidget (parentIndex, index, selectedWidgetId) {
			const choices = this.progressiveProfile.choices
			choices[parentIndex].choices[index].widgetId = selectedWidgetId
			this.updateProgressiveProfile('choices', choices)
		},
		updateProgressiveProfile (property, value) {
			this.$emit('update:progressiveProfile', {
				...this.progressiveProfile,
				[property]: value
			})
		},
		displayChannelName (channelId) {
			return this.channels.find(channel => channel.channelId === channelId)?.channelName
		},
		finalWidgetLabel (parentChannelId, channelId) {
			const finalLevelActions = []
			this.progressiveProfile.choices.forEach(choice => {
				choice.choices.forEach(subChoice => {
					const parentChannelId = choice.channelId
					finalLevelActions.push({
						...subChoice,
						parentChannelId
					})
				})
			})
			const finalActionIndex = finalLevelActions.findIndex(action => action.parentChannelId === parentChannelId && action.channelId === channelId)
			return `Final Widget ${finalActionIndex + 1} *`
		}
	}
}
</script>
