<template>
	<v-row>
		<v-col cols="12">
			<v-menu
				v-for="(element, index) in elements"
				:key="index"
				offset-y
				:close-on-content-click="false"
			>
				<template #activator="{ on }">
					<div class="mb-2 d-flex d-inline-flex cassie-customise-button-container cassie-input-width-md">
						<v-btn
							max-width="30"
							max-height="30"
							elevation="1"
							fab
							class="mr-2"
							:color="element.color"
							v-on="on"
							@click="openColourPicker(element)"
						/>
						<span class="text-caption mt-1">
							{{
								element.text
							}}
						</span>
					</div>
				</template>
				<div class="cassie-colour-picker-container white">
					<v-color-picker
						v-model="pickerColour"
						v-bind="$props"
						class="mx-auto"
						mode="hexa"
						hide-mode-switch
						@input="getColour($event, element, index)"
					/>
				</div>
			</v-menu>
		</v-col>
	</v-row>
</template>
<script>
export default {
	props: {
		elements: {
			type: Array,
			required: true
		},
		attach: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			showColourPicker: false,
			pickerColour: ''
		}
	},
	methods: {
		openColourPicker (element) {
			this.pickerColour = element.color
			this.showColourPicker = true
		},
		getColour (colour, element, index) {
			element.color = colour
			element.colour = colour
			const elements = this.elements
			elements[index].color = colour
			this.$emit('update:elements', elements)
			this.$emit('update-element-changed', element)
		}
	}
}

</script>
