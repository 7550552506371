var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Preview ")]},proxy:true},{key:"body",fn:function(){return [_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"5"}},[_c('v-tabs',{staticClass:"d-flex justify-end",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.deviceTypes),function(deviceType){return _c('v-tab',{key:deviceType.value},[_vm._v(" "+_vm._s(deviceType.name)+" ")])}),1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column justify-end",style:({
						width: _vm.previewContainerWidth,
						maxWidth: _vm.previewContainerWidth,
					}),attrs:{"id":"preview_container"}},[_c('div',{style:({
							backgroundColor: _vm.colourScheme.BackgroundColour,
							color: _vm.colourScheme.TextColour,
							'--widget-close-button-color': _vm.colourScheme.CloseButtonColour
						}),attrs:{"id":"cassie_widget__container"}},[_c('div',{attrs:{"id":"cassie_widget__header"}},[_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.header),expression:"header"}],attrs:{"id":"cassie_widget__header__content"}}),_c('button',{attrs:{"id":"cassie_widget__close"}},[_vm._v(" × ")])]),_vm._l((_vm.statements),function(statement){return _c('div',{key:statement,staticClass:"cassie-widget--statement"},[_c('input',{attrs:{"id":statement,"type":"checkbox","aria-label":"statement"}}),_c('label',{attrs:{"for":statement}},[_vm._v(" "+_vm._s(statement)+" ")])])}),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.footer),expression:"footer"}],attrs:{"id":"cassie_widget__footer"}})],2)])])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }