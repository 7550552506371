import { showSnackbar } from '../state/snackbar.js'
export default {
	setup: () => ({
		showSnackbar
	}),
	methods: {
		copyToClipboard (contents) {
			navigator.clipboard.writeText(contents)
			showSnackbar('Copied to clipboard')
		}
	}
}
