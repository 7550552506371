<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Details
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<TextField
							:value.sync="progressiveProfile.profileName"
							label="Name *"
							:rules="{required: true, max: 250}"
							:disabled="!userCanCreateAndUpdate"
							@input="updateProgressiveProfile('profileName', $event)"
						/>
					</v-col>
					<v-col cols="6">
						<Dropdown
							:value.sync="progressiveProfile.brandId"
							:disabled="isEdit || !userCanCreateAndUpdate || !clientMultiBranded || singleBrand"
							:items="brandItems"
							custom-sort
							label="Brand *"
							:rules="{ required: clientMultiBranded}"
							@input="updateProgressiveProfile('brandId', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<Textarea
							:value.sync="progressiveProfile.description"
							label="Description"
							:rules="{max: 2000}"
							:disabled="!userCanCreateAndUpdate"
							tooltip-text="Enter a meaningful description to help describe this profile (internal use only)"
							@input="updateProgressiveProfile('description', $event)"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<SectionCard
			:partially-collapsible="!!progressiveProfile.primaryWidgetId"
			:partially-collapsible-toggle-labels="['Show Preview', 'Show Preview']"
		>
			<template #title>
				Primary Widget Configuration Details
			</template>
			<template #subtitle>
				The Primary Preference Widget will be displayed first if a known Data Subject has not previously given a response to any of the statements configured.
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:value.sync="progressiveProfile.primaryWidgetId"
							:disabled="!userCanCreateAndUpdate"
							:items="preferenceWidgets"
							label="Widget *"
							rules="required"
							tooltip-text="Select the primary widget for this profile"
							@input="updatePrimaryWidget"
						/>
					</v-col>
				</v-row>
			</template>
			<template #collapsible-content>
				<v-row
					v-if="progressiveProfile.primaryWidgetId"
					dense
				>
					<v-col cols="12">
						<PreferenceWidgetPreviewCard
							:preference-widget="primaryPreferenceWidget"
							:extended-preferences="[]"
							:headers="headers"
							:user-full-permissions="userCanCreateAndUpdate"
							:footers="footers"
							:channels="channels"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
import { clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM, singleBrand } from '../../../../../../shared/state/brands.js'
import PreferenceWidgetPreviewCard from '../preference-widget-preview-card.vue'
export default {
	components: {
		SectionCard,
		TextField,
		Dropdown,
		Textarea,
		PreferenceWidgetPreviewCard
	},
	props: {
		progressiveProfile: Object,
		userCanCreateAndUpdate: Boolean,
		isEdit: Boolean,
		preferenceWidgets: Array,
		headers: Array,
		footers: Array,
		channels: Array,
		primaryPreferenceWidget: Object
	},
	setup () {
		return {
			brandOptions,
			clientMultiBranded,
			singleBrand
		}
	},
	computed: {
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	methods: {
		async updatePrimaryWidget (selectedWidgetId) {
			const preferenceWidget = this.preferenceWidgets?.find(widget => widget.preferenceWidgetId === selectedWidgetId)
			await this.updateProgressiveProfile('primaryWidgetId', selectedWidgetId)
			const secondLevelChannels = preferenceWidget.statementGroups?.map(statementGroup => ({
				channelName: statementGroup.channelName,
				channelId: statementGroup.channelId,
				widgetId: null,
				choices: []
			}))
			await this.updateProgressiveProfile('choices', secondLevelChannels)
		},
		updateProgressiveProfile (property, value) {
			this.$emit('update:progressiveProfile', {
				...this.progressiveProfile,
				[property]: value
			})
		}
	}
}
</script>
